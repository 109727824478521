import './bootstrap';
import '../css/app.css';
import '../css/additional-styles/utility-patterns.css';
import '../css/additional-styles/theme.css';

import '../css/inter/inter.css';

import { createSSRApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import mitt from 'mitt';
import LoadScript from 'vue-plugin-load-script';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import RolesPermissionsToVue from "../../vendor/geowrgetudor/laravel-spatie-permissions-vue/src/js";
import InertiaTitle from 'inertia-title/vue3';
import VueLazyload from 'vue3-lazyload'

//Navigation icons
import {faHouse, faSquareList, faTableColumns, faBank, faCreditCardFront, faFileImport} from "@fortawesome/pro-light-svg-icons";

// Field icons
import {faSpinnerThird, faTrash, faChevronDown as faChevronDownReg, faPlus} from "@fortawesome/pro-regular-svg-icons";
import {
    faArrowsRotate, faCircleInfo, faLink,
    faFileInvoiceDollar, faFloppyDisk as faFloppyDiskLight,
    faCalendar, faXmark, faGear as faGearLight
} from "@fortawesome/pro-light-svg-icons";
import {faFolderOpen, faFileCsv, faFileImport as faFileImportThin} from "@fortawesome/pro-thin-svg-icons";
import {faFileImport as faFileImportSolid, faEllipsisVertical} from "@fortawesome/pro-solid-svg-icons";
import {faGear, faChevronDown, faArrowRight, faMoon, faSunBright, faDisplay, faMoonStars,
        faMessagesQuestion, faCircleQuestion, faFloppyDisk, faDownload,
        faSparkles, faRightFromBracket, faArrowRightArrowLeft, faNewspaper, faRoad,
        faComment } from "@fortawesome/pro-duotone-svg-icons";
import store from "./store";
import * as Sentry from "@sentry/vue";

const emitter = mitt();
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Synci';

library.add(faHouse, faSquareList, faTableColumns, faBank, faCreditCardFront,
    faMessagesQuestion, faCircleQuestion, faSparkles, faGear, faRightFromBracket,
    faPlus, faSpinnerThird, faArrowsRotate, faFolderOpen, faCircleInfo,
    faSunBright, faMoonStars, faDisplay, faMoon, faLink, faFileInvoiceDollar,
    faFileImport, faFileCsv, faDownload, faFloppyDisk, faChevronDown,
    faFileImportSolid, faArrowRight, faArrowRightArrowLeft, faTrash, faFileImportThin,
    faCalendar, faXmark, faChevronDownReg, faFloppyDiskLight, faGearLight, faEllipsisVertical,
    faNewspaper, faRoad, faComment);

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const synci = createSSRApp({ render: () => h(App, props) })
            .use(plugin)
            .use(LoadScript)
            .use(ZiggyVue, Ziggy)
            .use(RolesPermissionsToVue)
            .use(InertiaTitle)
            .use(store)
            .use(VueLazyload)
            .component('font-awesome-icon', FontAwesomeIcon);

        synci.config.globalProperties.$emitter = emitter;

        Sentry.init({
            app: synci,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                Sentry.replayIntegration(),
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        synci.mount(el);
        return synci;
    },

    progress: {
        color: '#34D399',
    },
});
