import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            darkMode: true,
            // Other state properties...
        }
    },

    mutations: {
        setDarkMode(state, value) {
            state.darkMode = value;
        },
        // Other mutations...
    },
});

// Subscribe to the store updates
store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('store', JSON.stringify(state));
})

// Check if any data exists in local storage
if(localStorage.getItem('store')) {
    // Replace the state object with the stored item
    store.replaceState(
        Object.assign(
            {},
            store.state,
            JSON.parse(localStorage.getItem('store'))
        )
    );
}

export default store;
